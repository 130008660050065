@import "../../themes/colors.scss";

.tab-button {
    align-items: center;
    background-color: transparent;
    border: none;
    color: $offWhite;
    display: flex;
    flex-direction: column;
    font-size: 1.25rem;
    font-weight: bold;
    margin: 0 2rem 0.75rem 2rem;
    opacity: 0.35;
    padding: 0.25rem 0.5rem;

    &:hover {
        cursor: pointer;
    }

    &.active {
        color: $offWhite;
        border-bottom: 2px solid $offWhite;
        opacity: 1;

        .icon {
            // background-color: $offWhite;
        }
    }
}