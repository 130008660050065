@import "../../themes/colors.scss";

.stat-display {
    background-color: $navy;
    border-radius: 1rem;
    color: $offWhite;
    margin: 0.5rem;
    overflow: hidden;
    position: relative;
    width: 45%;

    &.blank {
        background-color: transparent;

        .title {
            background-color: transparent;
        }
    }

    .title {
        background-color: $offWhite;
        color: $darkPurple;
        font-size: 1.35rem;
        font-weight: bold;
        margin-bottom: 0.75rem;
        padding: 0.5rem;
        text-align: center;
    }
    
    .boosts {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-bottom: 1rem;
        font-style: italic;
    }

    .total {
        background-color: $lightGold;
        bottom: 0rem;
        color: #000000;
        font-weight: bold;
        padding-bottom: 0.15rem;
        padding-top: 0.15rem;
        position: absolute;
        width: 100%;
    }
}