$purple: #5f3dc9;
$darkPurple: #32197D;
$navy: #0d0d25;
$gold: #f59921;
$lightGold: #fcc479;
$offWhite: #f1e7ef;
$gray: #b3b3b3;
$maroon: #2B061E;
$error: #a50000;
$lightError: #cf1818;
