@import "./themes/colors.scss";

.app {
    align-items: center;
    color: $offWhite;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    position: relative;
    width: 100vw;

    justify-content: center;

    background-color: $purple;

    @media (min-width: 768px) {
        max-width: 768px;
    }

    .header {
        align-items: center;
        background-color: $navy;
        display: flex;
        font-size: 2rem;
        font-weight: bold;
        height: 6rem;
        padding: 0.75rem;
        position: absolute;
        top: 0;
        width: 100vw;

        .logo {
            height: 3.5rem;
            margin-right: 1rem;
            width: 3.5rem;
        }

        .header-button {
            text-decoration: none;
            position: absolute;
            right: 1rem;
        }
        
        .header-icon {
            margin-right: 0.5rem;
        }
    }

    .stat-inputs {
        display: flex;
        justify-content: center;
        margin-top: -0.5rem;
        width: 100%;
    }
    
    .message-box {
        border-radius: 0.75rem;
        font-size: 1.5rem;
        font-weight: bold;
        height: 3rem;
        margin-bottom: 0.75rem;
        margin-top: 0.75rem;
        padding-bottom: 0.25rem;
        padding-top: 0.25rem;
        text-align: center;
        width: 80%;

        &.warning {
            background-color: $lightGold;
            border: 2px solid $gold;
            color: #000000;
        }

        &.error {
            background-color: $lightError;
            border: 2px solid $error;
        }
    }

    .stat-displays {
        display: flex;
        height: 30%;
        justify-content: center;
        margin-bottom: 0.25rem;
        width: 100%;
    }

    .tab-buttons {
        display: flex;
        background-color: $navy;
        bottom: 0;
        justify-content: center;
        position: absolute;
        width: 100vw;
    }
}