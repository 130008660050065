@import "../../themes/colors.scss";

.icon {
    border-radius: 50%;
    font-size: 1.5rem;
    height: 1.5rem;
    padding: 0.25rem;
    width: 1.5rem;

    &.sm {
        font-size: 1.5rem;
        height: 1.5rem;
        padding: 0.25rem;
        width: 1.5rem;
    }

    &.md {
        font-size: 1.75rem;
        height: 1.75rem;
        padding: 0.25rem;
        width: 1.75rem;
    }

    &.lg {
        font-size: 3.5rem;
        height: 3.5rem;
        padding: 0.5rem;
        width: 3.5rem;
    }
}